/* 修改表格行高 */
.ant-table td.ant-table-cell {
  padding: 10px 16px;
}

/* 表格最低高度 */
.ant-table {
  min-height: 500px !important;
}

/* sider背景色 */
.ant-layout .ant-layout-sider {
  background: #001529;
}

.ant-layout-sider-children .ant-menu.ant-menu-dark {
  background-color: #001529;
}


.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected{
  font-weight: 500;
  border-left: 4px solid #035BAC;
  background: -webkit-linear-gradient(left, rgba(3,91,172,0.85),rgba(3,91,172,0)) !important;
}

/* tab阴影样式修改 */
.ant-tabs-nav{
   box-shadow: 0 3px 4px  rgba(0, 21, 41, .04) !important;
}