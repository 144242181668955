/* 单元格内容根据宽度自动省略 */
.show_ellipsis {
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100px;
  overflow: hidden;
}

/* 内容区头部的搜索和新建模块 */
.tableOperator {
  padding: 0 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* 内容区头部模块 */
main .commonHead {
  width: calc(100% - 268px);
  background: #fff;
  height: 62px !important;
  box-shadow: 0 3px 4px  rgba(0, 21, 41, .04);
  z-index: 999;
  position: fixed;
  top: 108px;
  left: 253px;
  padding: 0 40px !important;
}

/* 样式为title和button */
main .header-title-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* 头部标题 */
main .common-head-title {
  font-size: 18px;
  height: 24px;
  line-height: 24px;
  font-weight: 900;
  color: #3e3e3e;
}

/* 样式为tab栏 */
main .header-title-tab {
  height: 50px !important;
  font-size: 16px;
 
}

/* 各个模块的内容区 */
main .commonContent-title {
  margin-top: 62px;
  padding: 40px;
}

main .commonContent-tab  {
  margin-top: 50px;
  padding: 40px;
}