// 头部样式
.headDiv {
  background-color: #fff;
  color: #333;

  // title和用户信息模块
  .titleAndLogin {
    height: 64px;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    box-shadow: rgba(0, 21, 41, 0.12) 0px 1px 4px 0px;

    // 标题模块
    .titleContent {
      font-size: 22;
      font-weight: 700;
      display: flex;
      align-items: center;

      .projectLogo {
        width: 38px;
        height: 30px;
        margin: 0 10px 0 20px;
        background-image: url('../../assets/images/projectLogo.svg');
      }
    }

    .userInfoContent {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .userInfo {

        display: flex;
        align-items: center;

        .userAvatar {
          display: inline-block;
          // background-image: url('../../assets/images/homes.png');
          // background-repeat: repeat;
          // background-position: -161px -100px;
          margin-right: 8px;
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }

        .toLogout {
          margin: 0px 4px 0px 24px;
          cursor: pointer;
        }
      }

    }


  }

  // 面包屑模块
  .breadcrumbDiv {
    box-sizing: border-box;
    padding-top: 10px;
    padding-left: 24px;
    width: 100%;
    line-height: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    .ant-breadcrumb-link{
cursor: pointer;
    }
  }

}

.ant-layout-sider{
  min-width: 253px !important;
}



// 侧边栏样式
.SidebarDiv {
  display: flex;
  align-items: center;
  height: 40px;
  min-width: 20px;

  p {
    padding: 0;
    margin: 0
  }

  span {
    height: 14px;
    width: 14px;
    display: block;
    background: no-repeat center 100%;
    margin-right: 12px;
  }

  //首页
  .Home {
    background-image: url('../../assets/images/home.svg');
  }

  //项目
  .Project {
    background-image: url('../../assets/images/project.svg');
  }

  //工人
  .Workers {
    background-image: url('../../assets/images/worker.svg');
  }

  //考勤
  .Attendance {
    background-image: url('../../assets/images/attendance.svg');
  }

  //薪资   
  .Salary {
    background-image: url('../../assets/images/salary.svg');
  }

  //设备
  .Facility {
    background-image: url('../../assets/images/device.svg');
  }

  //系统
  .System {
    background-image: url('../../assets/images/system.svg');
  }
}
@primary-color: #035BAC;@link-color: #035BAC;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary : rgba(0, 0, 0, .45);@disabled-color : rgba(0, 0, 0, .25);@border-radius-base:  4px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15);